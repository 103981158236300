import Accordion from '@material-ui/core/Accordion'
import AccordionActions from '@material-ui/core/AccordionActions'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListSubheader from '@material-ui/core/ListSubheader'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import {MdExpandMore,} from 'react-icons/md'
import {BiFile, BiMoon} from 'react-icons/bi'
import {FiSun} from 'react-icons/fi'
import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce/lib";
import axios from "axios";

import "./App.css"

function addhttp(url) {
    if (!/^(?:f|ht)tps?\/\//.test(url)) {
        url = "http://" + url;
    }
    return url;
}

function App() {
  const [loading, setLoading] = useState(true)
  const [courses, setCourses] = useState()
  const [anchorMenu, setAnchorMenu] = useState(null)

  const [query, setQuery] = useState("")
  const [queryDebounce] = useDebounce(query, 700)

  const [darkTheme, setDarkTheme] = useState(true);

  const theme = createMuiTheme({
  palette: {
    type: darkTheme ? "dark" : "light"
  }
  })

  useEffect(() => {
    const themeType = localStorage.getItem("dark") || "dark";
    if (themeType !== "dark") {
      setDarkTheme(false);
    }

    axios.get("https://gist.githubusercontent.com/neelygenet/0baa1a62995268bfc1e4dfb8415fe14a/raw/raposastorrents.json?t=" + Date.now(), {
    })
      .then(res => {
        setCourses(res.data.all.sort((a, b) => (a.name > b.name ? 1 : -1)))
        setLoading(false)
      })
  }, []);

  const changeTheme = () => {
    localStorage.setItem("dark", darkTheme ? "light" : "dark");
    setDarkTheme(!darkTheme);
  };

  function closeMenu(){
    setAnchorMenu(null)
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <div className="mainContainer">
        <div className="flex">
          <img alt="raposas" height="50" src="https://cdn.jsdelivr.net/gh/neelygenet/raposas@v1.1.2/images/logo.png"/>
          <h1>Raposas<span className="light">Torrents</span></h1>
        </div>
        <div className="flex">
          <a className="noDecoration" href="https://raposas.net" rel="noreferrer" target="_blank">
           <Button>Indexador</Button>
          </a>
          <a className="noDecoration" href="https://docs.raposas.net/flashcards" rel="noreferrer" target="_blank">
            <Button>Flashcards</Button>
          </a>

          <Button onClick={e => setAnchorMenu(e.currentTarget)}>Mais</Button>
          <Menu
            anchorEl={anchorMenu}
            keepMounted
            open={Boolean(anchorMenu)}
            onClose={closeMenu}
          >
            <a className="noDecoration" href="https://docs.raposas.net/Copyright" rel="noreferrer" target="_blank">
              <MenuItem onClick={closeMenu}>Política de copyright</MenuItem>
            </a>
            <a className="noDecoration" href="https://docs.raposas.net/Privacidade" rel="noreferrer" target="_blank">
              <MenuItem onClick={closeMenu}>Política de privacidade</MenuItem>
            </a>
          </Menu>
        </div>
        <br/>
        <TextField
          label="Estou buscando por..."
          variant="outlined"
          fullWidth
          value={query}
          onChange={e => setQuery(e.target.value)}
        />
        <br/>

        {loading && <CircularProgress/>}

        {!loading && (
        <List
          subheader={
            <ListSubheader component="div">
              <div className="flex space-between">
                <p className="marginless">{queryDebounce.length > 0 ? "Resultados da pesquisa" : "Todos os cursos"}</p>
                <IconButton style={{padding: 3}} onClick={changeTheme}>
                  {darkTheme ?  <FiSun size={20}/> : <BiMoon size={20}/>}
                </IconButton>
              </div>
            </ListSubheader>
          }
          style={{width: "100%"}}
        >
          {courses.filter(course => course.name.toLowerCase().includes(queryDebounce.toLowerCase())).map(course => {
            return(
              <Accordion key={course.name} TransitionProps={{ unmountOnExit: true }} variant="elevation">
                <AccordionSummary expandIcon={<MdExpandMore/>}>
                  {course.name}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p className="marginless">Tamanho total: {course.totalSize}</p>
                    <p className="marginless">Link do curso: <a target="_blank" rel="noreferrer" className="link" href={addhttp(course.link)}>{course.link}</a></p>
                  </Typography>
                </AccordionDetails>
                <Divider />
                <AccordionActions >
                  <a className="noDecoration" href={addhttp(course.directFile)} rel="noreferrer" target="_blank"><Button size="small" startIcon={<BiFile/>} variant="outlined">Arquivo Torrent</Button></a>
                </AccordionActions>
              </Accordion>
            )
          })}
          
        </List>)}
      </div>
    </ThemeProvider>
  );
}

export default App;
